export default class {
  static addTargetBlank() {
    const links = document.querySelectorAll('a');
    const regex = new RegExp('https?:\/\/' +  window.location.host + '(.*?)', 'g');

    links.forEach(link => {
      if (!link.href.match(regex)) {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener');
      }
    });
  }

  static forEachPolyfill() {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window; // eslint-disable-line no-param-reassign
        for (let i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this);
        }
      };
    }
  }
}
