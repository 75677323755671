import '../styles/main.scss';
import Swiper from './vendors/swiper.min.js';
import $ from "jquery";
import Util from './modules/Utilities';

window.addEventListener('DOMContentLoaded', () => {
  Util.addTargetBlank();
})



$(window).on('load',function(){
  $("#burger").click(function(){
    $(this).toggleClass("act");
    $("#global-nav").slideToggle(200);
  });

});



$(function(){
   $('a[href*="#"]').click(function() {
      var speed = 400;
      var href= $(this).attr("href");
      var check=href.indexOf("#");
      var check=String(check);
      var text = href.substring(check);
      var target = $(text);
      var position = target.offset().top -150;
      $("body,html").animate({scrollTop:position}, speed, 'swing');
      $("#burger").toggleClass("act");
      $("#global-nav").slideToggle(200);
      return false;
   });
});
